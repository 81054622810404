<template>
  <form novalidate class="formluar max-width" @submit.prevent="submit">
    <b-form-group class="control-select" :state="!$v.form.discountType.$error" :label="$t('views.vouchers.discounts.form.discounttype')">
      <b-input-group>
        <div class="control-wrapper">
          <b-select v-model="form.discountType" :state="!$v.form.discountType.$error" :options="getDiscountTypesOptions()"/>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.discountType.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group class="control-select" :state="!$v.form.amountType.$error" :label="$t('views.vouchers.discounts.form.amounttype')">
      <b-input-group>
        <div class="control-wrapper">
          <b-select v-model="form.amountType" :state="!$v.form.amountType.$error" :options="getAmountTypesOptions()"/>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.amountType.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="showAmountTypeInput('Percent')" :state="!$v.form.percent.$error" :label="$t('views.vouchers.discounts.form.percent')">
      <b-input-group>
        <div class="control-wrapper">
          <b-input type="number" v-model="form.percent" :state="!$v.form.percent.$error"/>
        </div>
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.percent.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="showAmountTypeInput('Amount')" :state="!$v.form.amount.$error" :label="$t('views.vouchers.discounts.form.amount')">
      <b-input-group>
        <div class="control-wrapper">
          <b-input type="number" v-model="form.amount" :state="!$v.form.amount.$error"/>
        </div>
        <div class="input-group-append">
          <span class="input-group-text">CHF</span>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.amount.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="showDiscountTypeInput('Families')"  :state="!$v.form.familiesInput.$error" :label="$t('views.vouchers.discounts.form.families')">
      <b-input-group>
        <div class="control-wrapper">
          <b-input v-model="form.familiesInput" :state="!$v.form.familiesInput.$error"/>
          <small class="form-text text-muted">{{ $t('views.vouchers.discounts.form.hintcommaseparatatedlist') }}</small>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.familiesInput.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="showDiscountTypeInput('Products')"  :state="!$v.form.productsInput.$error" :label="$t('views.vouchers.discounts.form.products')">
      <b-input-group>
        <div class="control-wrapper">
          <b-input v-model="form.productsInput" :state="!$v.form.productsInput.$error"/>
          <small class="form-text text-muted">{{ $t('views.vouchers.discounts.form.hintcommaseparatatedlist') }}</small>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.productsInput.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="showDiscountTypeInput('Category')"  :state="!$v.form.categoryInput.$error" :label="$t('views.vouchers.discounts.form.categories')">
      <b-input-group>
        <div class="control-wrapper">
          <b-input v-model="form.categoryInput" :state="!$v.form.categoryInput.$error"/>
          <small class="form-text text-muted">{{ $t('views.vouchers.discounts.form.hintcommaseparatatedlist') }}</small>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.categoryInput.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="showDiscountTypeInput('Brands')"  :state="!$v.form.brandInput.$error" :label="$t('views.vouchers.discounts.form.brands')">
      <b-input-group>
        <div class="control-wrapper">
          <b-input v-model="form.brandInput" :state="!$v.form.brandInput.$error"/>
          <small class="form-text text-muted">{{ $t('views.vouchers.discounts.form.hintcommaseparatatedlistbrand') }}</small>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.categoryInput.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :state="!$v.form.customerInput.$error" :label="$t('views.vouchers.discounts.form.customer')">
      <b-input-group>
        <div class="control-wrapper">
          <b-input v-model="form.customerInput" :state="!$v.form.customerInput.$error"/>
          <small class="form-text text-muted">{{ $t('views.vouchers.discounts.form.hintcommaseparatatedlist') }}</small>
        </div>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.form.customerInput.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
    </b-form-group>
    <div class="form-actions">
      <div class="justify-content-end">
        <b-button @click="cancel" class="me-1"><b-icon icon="abort"/>{{$t('views.vouchers.discounts.form.cancel')}}</b-button>
        <b-button type="submit" variant="primary"><b-icon icon="send"/>{{$t('views.vouchers.discounts.form.save')}}</b-button>
      </div>
    </div>
  </form>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'

const DiscountTypes = ['All', 'Category', 'Products', 'Families', 'Brands']
const AmountTypes = ['Percent', 'Amount']
export default {
  name: 'DiscountForm',
  props: {
    discount: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        discountType: '',
        amountType: '',
        customerInput: '',
        familiesInput: '',
        productsInput: '',
        categoryInput: ''
      }
    }
  },
  validations () {
    return {
      form: {
        discountType: { required },
        amountType: { required },
        percent: {
          required: requiredIf((model) => {
            return this.form.amountType === 'Percent'
          })
        },
        amount: {
          required: requiredIf((model) => {
            return this.form.amountType === 'Amount'
          })
        },
        customerInput: {},
        familiesInput: {
          required: requiredIf((model) => {
            return this.form.discountType === 'Families'
          })
        },
        productsInput: {
          required: requiredIf((model) => {
            return this.form.discountType === 'Products'
          })
        },
        categoryInput: {
          required: requiredIf((model) => {
            return this.form.discountType === 'Category'
          })
        },
        brandInput: {
          required: requiredIf((model) => {
            return this.form.discountType === 'Brands'
          })
        }
      }
    }
  },
  created () {
    this.form = { ...this.discount }
    this.$set(this.form, 'customerInput', this.discount?.customers?.join(',') ?? '')
    this.$set(this.form, 'familiesInput', this.discount?.families?.join(',') ?? '')
    this.$set(this.form, 'productsInput', this.discount?.products?.join(',') ?? '')
    this.$set(this.form, 'categoryInput', this.discount?.categoryId?.join(',') ?? '')
    this.$set(this.form, 'brandInput', this.discount?.brands?.join(',') ?? '')
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const data = {
          discountType: this.form.discountType,
          amountType: this.form.amountType,
          percent: this.form.amountType === 'Percent' ? this.form.percent : null,
          amount: this.form.amountType === 'Amount' ? this.form.amount : null,
          products: this.form.discountType === 'Products' ? this.form.productsInput.split(',').map(itm => itm.trim()) : null,
          families: this.form.discountType === 'Families' ? this.form.familiesInput.split(',').map(itm => itm.trim()) : null,
          categoryId: this.form.discountType === 'Category' ? this.form.categoryInput.split(',').map(itm => itm.trim()) : null,
          brands: this.form.discountType === 'Brands' ? this.form.brandInput.split(',').map(itm => itm.trim()) : null,
          customers: this.form.customerInput ? this.form.customerInput.split(',').map(itm => itm.trim()) : null
        }
        this.$emit('form:submit', data)
      }
    },
    cancel () {
      this.$emit('form:cancel')
    },
    getDiscountTypesOptions () {
      return DiscountTypes.map(itm => ({ value: itm, text: this.$t(`views.vouchers.discounts.discounttypes.${itm.toLowerCase()}`) }))
    },
    getAmountTypesOptions () {
      return AmountTypes.map(itm => ({ value: itm, text: this.$t(`views.vouchers.discounts.amounttypes.${itm.toLowerCase()}`) }))
    },
    showDiscountTypeInput (discountType) {
      return this.form.discountType === discountType
    },
    showAmountTypeInput (amountType) {
      return this.form.amountType === amountType
    }
  }
}
</script>

<style lang="scss">
$formular-max-width: 550px !default;

$formular-actions-gap: $spacer !default;
$formular-actions-button-gap: $spacer * 0.125 !default;

$formular-controls-input-inline-min-width: auto !default;
$formular-controls-textarea-inline-min-width: 280px !default;
$formular-controls-select-inline-min-width: auto !default;

.formluar {
  .form-actions {
    margin-top: $formular-actions-gap;

    > div {
      display: flex;
      align-items: center;
      margin: $formular-actions-button-gap * - 1;

      .btn {
        margin: $formular-actions-button-gap;
      }
    }
  }

  &.is-inline {
    max-width: 100%;

    .form-controls {
      .control-input {
        min-width: $formular-controls-input-inline-min-width;
      }

      .control-textarea {
        min-width: $formular-controls-textarea-inline-min-width;
      }

      .control-select {
        min-width: $formular-controls-select-inline-min-width;
      }
    }

    .form-actions {
      margin-top: 0;
    }
  }

  &.max-width {
    max-width: $formular-max-width;
  }
}
</style>
