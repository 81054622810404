<template>
    <div>
      <h2>{{ $t('views.vouchers.discounts.edit.title') }}</h2>
      <DiscountForm :discount="getDiscountItem" @form:submit="submit" @form:cancel="navigate('Vouchers.Discounts')"></DiscountForm>
    </div>
  </template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import DiscountForm from '../../../components/vouchers/DiscountForm.vue'

export default {
  name: 'Vouchers.Discounts.Detail',
  components: { DiscountForm },
  mixins: [MixinEntityBase],
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  computed: {
    getDiscountId () {
      return this.$route.params.discountid
    },
    getDiscountItem () {
      const id = this.getDiscountId
      return this.entity?.value?.discounts ? this.entity.value.discounts[id] : {}
    }
  },
  created () {
    if (!this.entity?.value?.discounts) {
      this.navigate('Vouchers.Discounts')
    }
  },
  methods: {
    onInput (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onChange (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onSave (entity) {
      this.$store.dispatch(`${this.entity.key}/updateEntity`, entity)
    },
    submit (editDiscount) {
      const entity = { ...this.entity }
      entity.value.discounts[this.getDiscountId] = editDiscount
      this.onSave(entity)
      this.navigate('Vouchers.Discounts')
    }
  }
}
</script>
